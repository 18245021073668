import React from "react";
import Header from "../components/Header/index";
import SEO from "../components/SEO/seo";
import RideWaiting from "../components/BookingForm/Form-Steps/rideWaiting";

const SelectCar = ({ location: { state } }) => (
  <>
    <Header />
    <br />
    <br />
    <SEO title="Waiting for Ride" />
    <RideWaiting rideId={state ? state.id : 0} />
  </>
);

export default SelectCar;
