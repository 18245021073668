import gql from 'graphql-tag'
export const waitingForAccept = gql`
	subscription($data: ID!) {
		awaitDriver(rideId: $data) {
			rideId
			paymentType
			status
			firstName
			lastName
			phone
			email
			taxiName
			taxiModel
			taxiLicenseNo
			taxiColor
		}
	}
`
