import React, { Component, useEffect, useState } from "react";
import { Link, navigate } from "gatsby";
import "./index.css";
import { useQuery, useSubscription } from "@apollo/react-hooks";
import { GET_SETTING, GET_RIDE_STATUS } from "../graphql/queries";
import { toast } from "react-toastify";
import CountDown from "../../CountDown";
import { waitingForAccept } from "../graphql/subscriptions";
// Thursday, November 21, 2019
import { isBrowser } from "../../../utils/general";

const RideWaiting = ({}) => {
  let rideId = isBrowser() && localStorage.getItem("XXXX");
  let { data: rideData } = useQuery(GET_RIDE_STATUS, {
    variables: {
      id: rideId,
    },
  });
  if (rideData) {
    if (rideData.ride.status !== "pending") {
      if (rideData.ride.status === "accepted") {
        window.location.href = "/ride-accepted";
      } else {
        alert("Something went wrong. Please book ride again.");
        localStorage.removeItem("XXXX");
        window.location.href = "/";
      }
    }
  }
  useEffect(() => {
    console.log("RIDE WAITING MOUNTED");
    
  }, [])

  console.log(`SETTINGS ID------------- ${process.env.GATSBY_APP_SETTINGS_ID}`);
  const { data, error, loading } = useQuery(GET_SETTING, {
    variables: {
      data: "123",
    },
  });
  const {
    data: subsData,
    loading: subsLoading,
    error: subsError,
  } = useSubscription(waitingForAccept, { variables: { data: rideId } });

  toast.success("Ride Created Successfully", {
    autoClose: 1200,
  });
  if (loading) {
    return <h1>loading</h1>;
  }
  if (error) {
    return null;
  }
  if (subsError) {
    console.log("subscription error", subsError);
  }
  const { timeP1, timeP2, timeP3 } = data.setting;
  console.log("subsData: ", subsData);
  return (
    <section id="book-form">
      {!subsLoading && console.log("subscription data", subsData)}
      <CountDown
        seconds={timeP1 + timeP2 + timeP3}
        subscriptionData={(subsData && subsData.awaitDriver) || null}
      />
    </section>
  );
};

export default RideWaiting;
